.PlayerScreenJoin {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  padding: 2.4rem 2.4rem 4.8rem;
  box-sizing: border-box;
}
.PlayerScreenJoin-team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2.4rem 0 0;
  padding: 0;
  gap: 0.8rem;
}
.PlayerScreenJoin-team > li {
  display: inline-block;
}
.PlayerScreenJoin-actions {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  align-items: center;
}
.PlayerScreenJoin-start {
  background-color: var(--gensmak-neon);
}
.PlayerScreenJoin-start-fill {
  position: absolute;
  top: 0vh;
  left: 0vh;
  height: 100%;
  background-color: black;
}
.PlayerScreenJoin-start-label {
  color: var(--gensmak-neon);
  mix-blend-mode: difference;
}
