.HostBonusStickers {
  position: fixed;
  inset: 0;
  z-index: 10;
}
.HostBonusStickers,
.HostBonusStickers * {
  pointer-events: none;
}
.HostBonusStickers-background {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  z-index: 10;
}
.HostBonusStickers-sticker {
  position: absolute;
  z-index: 30;
  font-size: 1.8rem;
}
.HostBonusStickers-gen {
  top: 40%;
  left: 40%;
  width: 15rem;
  height: 15rem;
  object-fit: contain;
  rotate: 24deg;
}
.HostBonusSticker-outside-gen {
  top: 30%;
  left: 60%;
  rotate: -8deg;
}
.HostBonusSticker-no-hint {
  top: 15%;
  left: 30%;
  rotate: 12deg;
}
