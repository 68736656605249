.header {
  line-height: 1.5;
}
.par {
  margin-bottom: 0.5em;
}

html[data-device='host'] .header,
html[data-device='host'] .par {
  font-size: 2.2rem;
}
