.MarqueeRainbow-marquee {
  font-size: 4.8rem;
  padding: 0.6em 0;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--generation-color, var(--gensmak-neon));
}

.MarqueeRainbow-header {
  font-size: 2.4rem;
  font-weight: bold;
  background-color: var(--gensmak-neon);
  padding: 2vh;
}

html[data-device='host'] .MarqueeRainbow-marquee {
  font-size: 2.4rem;
  padding: 0.6em 0;
}
