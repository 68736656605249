#LoadingScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
}

#LoadingScreen > p {
  text-align: center;
  white-space: pre-line;
}

#LoadingScreen button {
  font-size: 1.5rem;
  padding: 20px;
  margin: 20px;
}

.LoadingScreen-smile {
  width: 100px;
  height: 100px;
  animation: rotate 3s infinite linear;
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
