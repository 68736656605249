.GenTag-name {
  padding: 0.37em 0.6em;
  font-size: 5.4rem;
  font-weight: bold;
  box-sizing: border-box;
  white-space: nowrap;
  --inset-color: var(--generation-color);
}

html[data-device='host'] .GenTag-name {
  font-size: 2.7rem;
}

.GenTag-small {
  background-color: var(--generation-color);
  font-size: 2.4rem;
}
