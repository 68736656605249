.HostPick-cards {
  padding: 5vw;
  display: flex;
  gap: 3vw;
}

.HostPick-card {
  position: relative;
  width: 30vh;
}

.HostPick-scores-section {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 0;
  padding: 4.8rem;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: space-between;
}
.HostPick-scores {
  display: flex;
  flex-direction: row;
  gap: 4.4rem;
  align-items: stretch;
  flex-wrap: nowrap;
  flex: 1;
}
.HostPick-scores > * {
  flex: 1;
}
.HostPick-logo {
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  flex: 0;
  margin-right: 100px;
  text-transform: uppercase;
}
.HostPick-food-fight-logo {
  max-width: 12.5rem;
  margin-left: -1rem;
}
.HostPick-scorecard {
  position: relative;
}
.HostPick-scorecard-front {
  z-index: 20;
}
