.PlayerScreenEnd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.PlayerScreenEnd a {
  color: #000;
  text-decoration: none;
}
.PlayerScreenEnd-buttons {
  padding: 4.8rem 2.4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.PlayerScreenEnd > :first-child {
  flex: 0 1 auto;
  overflow: hidden;
}
.PlayerScreenEnd-score {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2.4rem 4.8rem;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: stretch;
  gap: 3.2rem;
}

.PlayerScreenEnd-stickers {
  inset: 0 0 24rem;
}
