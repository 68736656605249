body {
  --difficulty-color-1: #5cac54;
  --difficulty-color-2: #ffbc00;
  --difficulty-color-3: #ee4000;
  --generation-color-greatest: #9e69e8;
  --generation-color-boomer: #ffbc00;
  --generation-color-genx: #f85b21;
  --generation-color-millennial: #f6aac9;
  --generation-color-genz: #00ba63;
  --gensmak-neon: #dafe07;
  --correct-color: #6fdd70;
  --wrong-color: #f34040;
  --border-radius: 20px;
  --border-size: 3px;
}

.difficulty-1 {
  --difficulty-color: var(--difficulty-color-1);
}

.difficulty-2 {
  --difficulty-color: var(--difficulty-color-2);
}

.difficulty-3 {
  --difficulty-color: var(--difficulty-color-3);
}

.generation-greatest,
.generation-1922 {
  --generation-color: var(--generation-color-greatest);
}

.generation-boomer,
.generation-1946 {
  --generation-color: var(--generation-color-boomer);
}

.generation-genx,
.generation-1965 {
  --generation-color: var(--generation-color-genx);
}

.generation-millennial,
.generation-1981 {
  --generation-color: var(--generation-color-millennial);
}

.generation-genz,
.generation-1997 {
  --generation-color: var(--generation-color-genz);
}

.roundShadow {
  border: var(--border-size) solid var(--round-shadow-color, #000);
  border-radius: var(--border-radius);
  --shadow-offset: 8px;
  box-shadow: var(--shadow-offset) var(--shadow-offset)
    var(--round-shadow-color, #000);
  overflow: hidden;
  transition: box-shadow 0.25s, translate 0.25s;
  translate: 0 0;
}

button.roundShadow:hover {
  box-shadow: calc(var(--shadow-offset) * 1.5) calc(var(--shadow-offset) * 1.5)
    var(--round-shadow-color, #000);
  translate: calc(var(--shadow-offset) * -0.5) calc(var(--shadow-offset) * -0.5);
}

button.roundShadow:active {
  box-shadow: calc(var(--shadow-offset) * 0.5) calc(var(--shadow-offset) * 0.5)
    var(--round-shadow-color, #000);
  translate: calc(var(--shadow-offset) * 0.5) calc(var(--shadow-offset) * 0.5);
}

button.roundShadow:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.insetShadow {
  position: relative;
  color: #000;
  background-color: transparent;
  border: none;
}
.insetShadow:before {
  content: "";
  background-color: var(--inset-color, transparent);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  translate: 6px 5px;
  pointer-events: none;
}
.insetShadow:after {
  content: "";
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000;
  z-index: 1;
  pointer-events: none;
}
