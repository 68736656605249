.button {
  background-color: white;
  font-size: 3.2rem;
  color: black;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  white-space: normal;
  box-sizing: border-box;
}
