.JoinScreen,
.JoinScreen > * {
  box-sizing: border-box;
}
.JoinScreen,
.JoinScreen-teams,
.JoinScreen-join {
  display: flex;
  width: 100%;
  height: 100%;
}
.JoinScreen-teams,
.JoinScreen-join {
  flex-direction: column;
}
.JoinScreen-join {
  padding: 3.2rem;
  align-items: stretch;
  justify-content: space-between;
  border-right: 1px solid rgba(0, 0, 0, 0.24);
  height: 100%;
}
.JoinScreen-instructions-button {
  margin: 0 auto 0 0;
  width: fit-content;
  border: 3px solid black;
  border-radius: 1.2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  /* gap: 0.8rem; */
}
.JoinScreen-teams {
  padding: 1.2rem;
  /* gap: 2.4rem; */
}
.JoinScreen-team:first-child:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.24);
  position: absolute;
  bottom: -1.2rem;
}
.JoinScreen > *,
.JoinScreen-team {
  flex: 1;
  position: relative;
  min-width: 50%;
}
.JoinScreen-no-players {
  font-size: 1.6rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #b0b0b0;
  font-weight: 700;
}
.JoinScreen-join-card {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2.4rem;
  flex-wrap: wrap;
}
.JoinScreen-join-card > h3 {
  text-decoration: underline;
  background-color: #e3ff3c;
  padding: 0.8rem;
}
.JoinScreen-join-qrcode {
  --size: 16rem;
  min-width: var(--size);
  min-height: var(--size);
}
.JoinScreen-team > h3 {
  padding: 2.4rem;
}
.JoinScreen-player {
  position: absolute;
  translate: -50% 0;
  z-index: 1;
}
