.button {
  background-color: white;
  font-size: 2.4rem;
  font-weight: bold;
  color: black;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  white-space: normal;
  background: transparent;
  border: none;
  transition: all 0.2s ease;
}
.button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: mouse;
}
