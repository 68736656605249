.HostSetupInstructions {
  position: relative;
  --margin: 3.2rem;
  --size: calc(100% - var(--margin) * 2);
  width: var(--size);
  height: var(--size);
  margin: var(--margin);
  border-radius: 1.6rem;
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3.6rem;
  box-sizing: border-box;
}
.HostSetupInstructions h3 {
  text-align: center;
}
.HostSetupInstructions-instructions {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 1.6rem;
  flex: 1;
  overflow: hidden;
}
.HostSetupInstructions-option {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.HostSetupInstructions-option p {
  text-align: center;
  margin: 0.8rem;
}
.HostSetupInstructions-option img {
  flex: 1;
  overflow: inherit;
  object-fit: contain;
}
.HostSetupInstructions-done-button {
  font-size: 1.6rem;
  width: fit-content;
  min-height: fit-content;
  padding: 0.8rem 3.2rem;
  background-color: white;
  margin: 0 0 0 auto;
}
