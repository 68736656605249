.card {
  height: fit-content;
  text-align: left;
}

.card > * {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  max-height: 100%;
  width: 100%;
}
.card > .card-background {
  position: relative;
}
.card-shadow {
  translate: 8px 8px;
}

.card-category-text {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  margin-top: auto;
  height: min-content;
  text-align: left;
  font-size: 1.6rem;
  width: 82%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
  text-transform: capitalize;
  word-break: break-word;
}
