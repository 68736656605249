.HostExpansionCard-pack {
  width: 18rem;
  height: 18rem;
  position: relative;
  flex: 0 0 18rem;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  cursor: pointer;
}
.HostExpansionCard-pack.disabled {
  cursor: not-allowed;
}
.HostExpansionCard-pack > .status {
  text-transform: uppercase;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  padding: 0.6rem 1.2rem;
  margin: 0.8rem;
  border-radius: 9999px;
  color: white;
  font-weight: bold;
}
.HostExpansionCard-pack > .status.new {
  background: var(--gensmak-neon);
  color: black;
}
.HostExpansionCard-pack.selected {
  scale: 1.2;
  margin: 0 2.4rem 0 1.6rem;
  z-index: 10;
  box-shadow: 0.8rem 0.8rem 0 var(--gensmak-neon);
  outline: none;
}
.HostExpansionCard-pack > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.HostExpansionCard-pack > h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  font-style: italic;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: start;
}
