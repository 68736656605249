.PlayerScreenPlayWait {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  min-height: 100%;
  overflow: hidden;
  position: relative;
}
.PlayerScreenPlayWait-main {
  flex: 1;
  position: relative;
  width: 100%;
}

.PlayerScreenPlayWait-hang {
  font-size: min(10vw, 5rem);
  padding: 2.4rem;
  line-height: min(11vw, 6rem);
}

.PlayerScreenPlayWait-stickers {
  inset: 0 0 12rem;
}

.PlayerScreenPlayWait-last {
  position: absolute;
  bottom: 2.4rem;
  right: 2.4rem;
  left: 2.4rem;
  border-radius: var(--border-radius);
  color: #000;
  background-color: #000;
  font-size: clamp(1.5rem, 3vmax, 2.4rem);
  padding: 0.6em;
  box-sizing: border-box;
  border-radius: 1em;
  --border-color: transparent;
  --border-width: 10px;
  box-shadow: 0 0 0 var(--border-width) var(--border-color);
  border: 2px solid #000;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.PlayerScreenPlayWait-last-correct {
  --border-color: var(--correct-color);
}

.PlayerScreenPlayWait-last-incorrect {
  --border-color: var(--wrong-color);
}

.PlayerScreenPlayWait-last-text {
  padding-bottom: 0.8rem;
}

.PlayerScreenPlayWait-ad {
  background: #000;
  width: 100%;
}

.PlayerScreenPlayWait-ad h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  text-align: center;
}

.PlayerScreenPlayWait-ad-container {
  width: 100%;
  max-height: 30vh;
}

.PlayerScreenPlayWait-adsbygoogle {
  display: block;
}

@media (min-aspect-ratio: 1/1) {
  .PlayerScreenPlayWait {
    flex-direction: row;
  }
  .PlayerScreenPlayWait-ad {
    width: 50vw;
    max-height: 100%;
  }
  .PlayerScreenPlayWait-last {
    bottom: 0;
  }
  .PlayerScreenPlayWait-hang {
    font-size: min(6vw, 3rem);
    padding: 0.8rem;
    line-height: min(7vw, 4rem);
    min-height: 70vh;
  }
}
