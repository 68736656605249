.BonusSticker {
  display: flex;
  font-size: 1.6rem;
  gap: 0.75em;
  padding: 0.75em;
  align-items: center;
  border-radius: 0.75em;
  border: 3px solid #000;
  background-color: var(--gensmak-neon);
}
.BonusSticker p {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
}
.BonusSticker p.bonus {
  font-size: 3em;
}
