.HostQuestion {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.HostQuestion-display {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--generation-color);
  position: relative;
  flex: 1 0 50%;
}
.HostQuestion-card {
  position: relative;
  height: 50%;
}
.HostQuestion-question {
  flex: 1 0 50%;
  padding: 3.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.HostQuestion-logo {
  position: absolute;
  bottom: 3.2rem;
  left: 3.2rem;
  font-size: 2rem;
  font-weight: bolder;
  font-style: italic;
  text-transform: uppercase;
}
/* TODO: this is almost dupe to scorecard, componentize? */
.HostQuestion-team-info {
  margin-top: auto;
}
.HostQuestion-team-info h3 {
  font-size: 1.6rem;
}
.HostQuestion-team {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.4em 0;
  padding: 0;
  gap: 0.2em;
}
.HostQuestion-team > li {
  display: inline-block;
  padding: 0.125em 0.3em;
  font-size: 0.6em !important;
}
