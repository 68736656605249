.StartScreen-header {
  padding: 3.2rem;
  position: absolute;
  top: 0;
  left: 0;
}
.StartScreen-header * {
  margin: 0;
  padding: 0;
  transition: all 0.4s ease;
}
.StartScreen-header h1 {
  font-size: 8em;
  font-style: italic;
  line-height: 1.2;
  margin-bottom: 0.2em;
}
.StartScreen-header h2 {
  font-size: 4.3em;
  line-height: 1.35;
}
