.PlayerInstructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 7.2rem 2.4rem;
  box-sizing: border-box;
}
.PlayerInstructions > h2 {
  margin: auto;
  text-align: center;
}
