.HostInstructions {
  position: relative;
  --margin: 3.2rem;
  --size: calc(100% - var(--margin) * 2);
  width: var(--size);
  height: var(--size);
  margin: var(--margin);
  border-radius: 1.6rem;
  border: 2px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 3.6rem 3.6rem;
  box-sizing: border-box;
}
.HostInstructions h3 {
  text-align: center;
}

.HostInstructions-buttons {
  display: flex;
  gap: 1.6rem;
  margin-left: auto;
}
.HostInstructions-buttons button {
  width: fit-content;
  font-size: 1.2rem;
  padding: 0.8rem 3.2rem;
}
.HostInstructions-time-bars {
  display: flex;
  gap: 1.2rem;
  width: 100%;
}
.HostInstructions-time-bar {
  flex: 1;
  height: 0.4rem;
  border-radius: 999px;
  background-color: #dbdbdb;
  overflow: hidden;
  position: relative;
}
.HostInstructions-time-bar-fill {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 999px;
}
.HostInstructions-cards {
  display: flex;
  gap: 3rem;
  justify-content: center;
  transition: all 0.5s ease;
  width: 100%;
}
.HostInstructions-card-wrapper {
  position: relative;
  width: 12rem;
  transition: width 0.5s ease;
  transition-delay: var(--card-delay);
}
.HostInstructions-card {
  position: relative;
  width: 12rem;
  rotate: var(--card-rotate);
  transition: translate 0.5s ease;
}
.HostInstructions-cards.collapsed {
  gap: 0;
}
.HostInstructions-cards.collapsed .HostInstructions-card-wrapper {
  width: 2rem;
}
.HostInstructions-cards.collapsed .HostInstructions-card {
  translate: -50%;
}
.HostInstructions-stickers {
  display: flex;
  gap: 4rem;
}
.HostInstructions-cards-points {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
  font-size: 3.6rem;
  font-weight: bold;
}
.HostInstructions-cards-points .HostInstructions-cards {
  gap: 1.5rem;
}
.HostInstructions-cards-points .HostInstructions-card-wrapper {
  width: 9rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}
.HostInstructions-cards-points .HostInstructions-card {
  width: 9rem;
}
.HostInstructions-check {
  width: 2rem;
  height: 2rem;
}
