.PlayerScreenPlayQuestion {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.PlayerScreenPlayQuestion-display {
  padding: min(3.2rem, 3vh);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--generation-color);
  position: relative;
  flex: 0 1 30%;
  overflow: hidden;
}
.PlayerScreenPlayQuestion-card {
  position: relative;
  height: 100%;
}

.PlayerScreenPlayQuestion-question {
  padding: 1.2rem 2.4rem;
  box-sizing: border-box;
  flex: 1 0;
  display: flex;
  flex-direction: column;
}
.PlayerScreenPlayQuestion-prompt {
  margin-top: auto;
  transition: opacity 0.3s ease;
  transition-delay: 0.3s;
}
