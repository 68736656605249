.TimedNotification {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 30rem;
  background: var(--gensmak-neon);
  border-radius: 1.6rem;
  padding: 2.4rem;
  margin: 2.4rem;
  font-size: 1.6rem;
  z-index: 30;
}
