.PlayerScreenName {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 2.4rem 2.4rem 4.8rem;
  box-sizing: border-box;
}

.PlayerScreenName-title {
  text-align: center;
  margin: auto 0 2.4rem;
}
.PlayerScreenName-next {
  margin: auto 0 0;
}

.PlayerScreenName-input {
  margin-bottom: 2.4rem;
}
.PlayerScreenName.shifted .PlayerScreenName-title {
  margin: 1.2rem 0 2.4rem;
}
.PlayerScreenName.shifted .PlayerScreenName-next {
  margin: 1.2rem 0 auto;
}
