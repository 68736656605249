.PlayerScreenGeneration {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding: 2.4rem 2.4rem 4.8rem;
  box-sizing: border-box;
}
.PlayerScreenGeneration-title {
  text-align: center;
  margin-bottom: 1.2rem;
}
.PlayerScreenGeneration-generations {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: min(1.6rem, 1.2vh);
  gap: min(2rem, 1.5vh);
}
.PlayerScreenGeneration-generation {
  flex: 1;
  padding-top: min(2rem, 1.5vh);
  padding-bottom: min(2rem, 1.5vh);
  font-size: min(5.4rem, 5vh);
}
.PlayerScreenGeneration-next {
  margin-top: 2.4rem;
  flex: 0 0 auto;
}
