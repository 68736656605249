.GenInput-wrapper {
  padding: 2rem 3.2rem;
  box-sizing: border-box;
  --inset-color: var(--generation-color);
}
.GenInput-wrapper > input {
  font-size: 5.4rem;
  font-weight: bold;
  white-space: nowrap;
  outline: none;
  width: 100%;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.GenInput-wrapper > input::placeholder {
  color: black;
  opacity: 0.5;
}
