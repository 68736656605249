.GameMenuButton {
  border: none;
  background: transparent;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.8rem;
  margin: 0.8rem;
  cursor: pointer;
  border-radius: 1.2rem;
  transition: background-color 0.2s ease;
}
.GameMenuButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.GameMenuButton > img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}
