.HostExpansionMenu {
  display: flex;
  gap: 8rem;
  padding: 4rem;
  box-sizing: border-box;
  max-height: 100%;
}
.HostExpansionMenu > * {
  flex: 1;
}
.HostExpansionMenu-description {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.HostExpansionMenu-description > h2 {
  font-style: italic;
}
.HostExpansionMenu-description > p {
  font-size: 2rem;
  margin-bottom: auto;
}
.HostExpansionMenu-pack {
  position: relative;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  cursor: pointer;
  box-shadow: 0.8rem 0.8rem 0 var(--gensmak-neon);
}
.HostExpansionMenu-pack > .status {
  text-transform: uppercase;
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  padding: 0.4rem 0.8rem;
  margin: 0.2rem;
  border-radius: 9999px;
  color: white;
}
.HostExpansionMenu-pack > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.HostExpansionMenu-pack > h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  font-style: italic;
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
}
.HostExpansionMenu button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 3rem;
}

.HostExpansionMenu.vertical {
  flex-direction: column;
  gap: 2rem;
}

.HostExpansionMenu.horizontal .HostExpansionMenu-pack {
  width: 100%;
}

.HostExpansionMenu.vertical .HostExpansionMenu-pack {
  overflow: hidden;
}
.HostExpansionMenu.vertical button {
  font-size: 2rem;
}
