.GameMarquee-marquee {
  padding: 1em 0;
  font-size: 2.4rem;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}

html[data-device='host'] .GameMarquee-marquee {
  font-size: 1.6rem;
  padding: 0.6em 0;
}

html[data-device='host'] .GameMarquee-marquee.game-over {
  font-size: 2.4rem;
}

.GameMarquee-marquee.game-over {
  font-size: 4.8rem;
  padding: 0.6em 0;
}
