.ResultSticker-background {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 3.5rem;
  font-weight: bold;
  opacity: 1;
}

.ResultSticker-sticker {
  border: var(--border-size) solid black;
  border-radius: var(--border-radius);
  padding: 24px;
  rotate: -5deg;
}

.ResultSticker-sticker.correct {
  background: var(--correct-color);
}

.ResultSticker-sticker.incorrect {
  background: var(--wrong-color);
}
