.InstructionButtons {
  display: flex;
  gap: 2.4rem;
}

.InstructionButtons button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 2.4rem;
}

.InstructionButtons-start {
  background-color: var(--gensmak-neon);
}
